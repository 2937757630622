export const base64ToPdfElement = url => `
  <embed
    type="application/pdf"
    src="${url}"
    width="100%"
    height="100%"
    align="middle"
  />
`;

export const base64ToImgElement = url => `
  <img
    src="${url}"
    width="100%"
    margin="auto"
    align="middle"
  />
`;

export const previewInNewTab = (data, title = 'Prévisualisation') => {
  const document = window.open('about:blank', '_blank').document;
  document
    .write(`
    <html style="background-color: rgb(204, 204, 204);">
      <body
        style="width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          right: 0;
          overflow: auto;"
      >
        ${data}
      </body>
    </html>
  `);
  document.title = title;
  document.close();
};

export const openPreviewPdf = (data, title) =>
  previewInNewTab(base64ToPdfElement(data), title);
